import { admin2 } from './instance'

// => 查詢TapPay設定
export const GetTapPayConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/tapPayConfig`,
  })
}

// => 更新TapPay設定
export const UpdateTapPayConfig = async ({
  shopId,
  enabled,
  env,
  partnerKey,
  partnerAccount,
  allowedSalesPaymentUsers,
  allowedDirectPaymentUsers,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/tapPayConfig`,
    data: {
      enabled,
      env,
      partnerKey,
      partnerAccount,
      allowedSalesPaymentUsers,
      allowedDirectPaymentUsers,
    },
  })
}

// => 查詢可綁定 Tappay 用戶列表
export const FindAllowBindUserList = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/tapPayConfig/allowBindUserList`,
  })
}
