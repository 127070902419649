<template>
  <BaseDialog
    title="編輯 iPhone 卡緊收參數設定"
    width="670px"
    btn1="儲存"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <el-form-item prop="enabled" label="是否啟用 iPhone 卡緊收收款">
        <el-switch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>
      <el-form-item prop="partnerAccount" label="TapPay Partner Account">
        <el-input v-model="formData.partnerAccount" :disabled="!formData.enabled" />
      </el-form-item>
      <el-form-item prop="partnerKey" label="TapPay Partner Key">
        <el-input v-model="formData.partnerKey" :disabled="!formData.enabled" />
      </el-form-item>
      <el-form-item prop="AllowedSalesPaymentUsers" label="卡緊收一般收款綁定">
        <el-select v-model="formData.AllowedSalesPaymentUsers" :disabled="!formData.enabled" multiple>
          <el-option
            v-for="item in allowBindUserList"
            :key="item.id"
            :label="`${item.name} (${item.id.slice(-5)})`"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="AllowedDirectPaymentUsers" label="卡緊收快速收款綁定">
        <el-select v-model="formData.AllowedDirectPaymentUsers" :disabled="!formData.enabled" multiple>
          <el-option
            v-for="item in allowBindUserList"
            :key="item.id"
            :label="`${item.name} (${item.id.slice(-5)})`"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <p class="text-[#636363] text-[14px] mb-[20px]">提醒：若在列表中找不到欲綁定帳號，請返回「組織首頁」→「管理員設定」，確認該帳號身份別需為「集團管理員」、「運營管理員」、「車隊隊長」或「服務車次-卡緊收」</p>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent, ref, computed, onMounted } from 'vue'
import { noEmptyRules } from '@/validation'
import { get, map } from 'lodash'
import { UpdateTapPayConfig, FindAllowBindUserList } from '@/api/tapPay'
import { useBaseForm } from '@/use/useForm'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'TapPayEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => {} },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    // const partnerDisabled = ref(false)
    const allowBindUserList = ref([])
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: null,
      partnerAccount: undefined,
      partnerKey: undefined,
      AllowedSalesPaymentUsers: [],
      AllowedDirectPaymentUsers: [],
    })
    const formRules = {
      enabled: [noEmptyRules()],
      partnerAccount: [noEmptyRules()],
      partnerKey: [noEmptyRules()],
    }
    const submitData = computed(() => {
      const data = { ...formData }
      return data
    })

    const findAllowBindUserList = async () => {
      const [res, err] = await FindAllowBindUserList({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      allowBindUserList.value = res
    }
    const handleSubmit = async (data) => {
      const [res, err] = await UpdateTapPayConfig({
        shopId: shopId.value,
        enabled: get(data, 'enabled'),
        partnerKey: get(data, 'partnerKey'),
        partnerAccount: get(data, 'partnerAccount'),
        allowedSalesPaymentUsers: filteredItem(get(data, 'AllowedSalesPaymentUsers')),
        allowedDirectPaymentUsers: filteredItem(get(data, 'AllowedDirectPaymentUsers')),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }
    const filteredItem = (data) => {
      const list = allowBindUserList.value.map(item => item.id)
      return data.filter(item => list.includes(item))
    }
    const syncData = async () => {
      const config = props.configData
      console.log(config, get(config, 'enabled'), formData)
      formData.enabled = get(config, 'enabled')
      formData.partnerKey = get(config, 'partnerKey')
      formData.partnerAccount = get(config, 'partnerAccount')
      formData.AllowedSalesPaymentUsers = map(config.AllowedSalesPaymentUsers, 'id')
      formData.AllowedDirectPaymentUsers = map(config.AllowedDirectPaymentUsers, 'id')
      // if (get(config, 'partnerKey') || get(config, 'partnerAccount')) partnerDisabled.value = true
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }
    onMounted(async () => {
      await findAllowBindUserList()
      await syncData()
    })
    return {
      loading,
      formData,
      formRef,
      formRules,
      allowBindUserList,
      // partnerDisabled,
      onConfirm,
    }
  },
})
</script>
  <style lang="postcss" scoped>
  ::v-deep .el-input, ::v-deep .el-switch, ::v-deep .el-select {
    @apply w-full
  }
  ::v-deep .el-select, ::v-deep .el-select .el-input {
    @apply h-auto
  }
  </style>
